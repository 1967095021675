import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo"
import data from '../data/content.json'
import ContactForm from '../components/parts/ContactForm'
import ContactInformation from '../components/parts/ContactInformation'
import Masonry from 'react-masonry-component';

export default class Products extends Component {
    state = {
        products: []
    }

    componentDidMount()  {
        window.scrollTo(0,0);
        if (data) {
            this.setState({
                products: data.products
            });
        }
    }

    render() {

        const { products } = this.state;

    if (products === null) return <div className="home-loading">loading...</div> 
        return (
            <Layout>
                <SEO title="products"  description="Check out our recent products. Here are a few products our clients have requested over the years. Contact us with all your requests." />
                <div className="products-wrap">
                    <h1 className="page-title">Recent Products</h1>
                    <h2 className="page-subtitle">Here are a few products our clients have requested over the years. Contact us with all your requests.</h2>
                    <main>
                        
                        <Masonry
                            className={'my-gallery-class'} // default ''
                            elementType={'div'} // default 'div'
                            disableImagesLoaded={false} // default false
                            updateOnEachImageLoad={false} // default false and products only if disableImagesLoaded is false
                            // imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                            {products.map( (work, idx) => {
                                return (
                                    <div className="products-img" key={idx}>
                                        <img src={`/images/${work.src}`} alt={`${work.name} product sample`} />
                                    </div>
                                )
                            })}
                        </Masonry>
                    </main>

                    <section className="products-contact-form">
                        <h1 className="page-title">Like what you see?</h1>
                        <h2 className="page-subtitle">Request a quote or ask us anything!</h2>
                        <ContactForm />
                    </section>
                    <section>
                        <ContactInformation />
                    </section>
                </div>
            </Layout>
        )
    }
}
